// import { Link } from "gatsby"
import React, { useEffect } from "react"
// import PropTypes from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"

// s4n
// import "./../../../assets/sass/components/service/scroll-top.scss"


const ScrollTop = (props) => {
    const { children, intl, serviceId } = props;

    const loadScripts = async (props) => {
        // for this feature we enable it in in layout.js loaded scripts
    }

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {
        loadScripts(props);
    }, []);

    return (
        <div id="scrollTop" className="scrollTop">
            <a href="#top" title="Go to top">
                <i className="fas fa-angle-up"></i>
            </a>
        </div>
    )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default injectIntl(ScrollTop)
